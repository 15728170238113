import { Modal } from "flowbite-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillYoutube } from "react-icons/ai";

const YoutubeButton = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      <div
        role="button"
        onClick={() => setShow(true)}
        className="pl-3 pr-4 py-2 bg-white rounded-md shadow-sm border border-gray-300 justify-center items-center gap-1 flex overflow-hidden "
      >
        <AiFillYoutube className="text-red-500 w-6 h-6 relative overflow-hidden md:visible  sm:invisible" />
        {t("dashboard.features.lmc.general.tutorial")}
      </div>

      <Modal
        size={"lg"}
        show={show}
        dismissible
        onClose={() => setShow(false)}
        style={{ maxWidth: "100%" }}
      >
        <Modal.Header className="border-0 items-start">
          <span className="text-zinc-900 text-xl font-['Sofia Sans'] items-start justify-between">
            {t("dashboard.features.lmc.general.tutorial")}
          </span>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src="https://www.youtube.com/embed/esMC2SQZxXQ"
            width="100%"
            className="md:h-[28rem] sm:h-full"
            style={{ border: "none" }}
            title="Tutorial Iframe"
          ></iframe>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default YoutubeButton;
