import { Button } from "flowbite-react";
import YoutubeButton from "./YoutubeButton";
import { useTranslation } from "react-i18next";

const LMCButtons = ({ handleCancelEdit }: { handleCancelEdit: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className="mb-3 flex gap-4 place-content-between">
      <YoutubeButton />

      <div className="flex justify-end  gap-4">
        <Button color={"light"} onClick={handleCancelEdit}>
          {t("dashboard.features.lmc.general.cancel")}
        </Button>

        <Button type="submit" className="bg-sky-500">
          {t("dashboard.features.lmc.general.save")}
        </Button>
      </div>
    </div>
  );
};

export default LMCButtons;
