import { joiResolver } from "@hookform/resolvers/joi";
import { AxiosError } from "axios";
import { Button, Label, Modal, Textarea, TextInput } from "flowbite-react";
import * as Joi from "joi";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BiChevronDown,
  BiChevronUp,
  BiPaperclip,
  BiShow,
  BiTrash,
} from "react-icons/bi";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import HttpService from "../../../../services/http";
import { ACCEPTED_FILE_TYPES } from "../../../../constants/fileTypes";

type propTypes = {
  show: boolean;
  ctrFn: Dispatch<SetStateAction<boolean>>;
  toSendMsgUserId: string;
  entity_identification: string;
  entity_user_identification: string;
  userName: string;
};

type sendMsgFormType = {
  template?: string;
  subject: string;
  content: string;
  attachments?: FileList; // Add a new field for file attachments
};

const options = [
  {
    value: "old",
    label: "Default",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/2502031554528_email_temp_default-min.png",
  },
  {
    value: "white",
    label: "White",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/25020312585997_email_temp_white-min.png",
  },
  {
    value: "elegant",
    label: "Elegant",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/25020315545150_email_temp_elegant-min.png",
  },
  {
    value: "default",
    label: "Cyan",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/2502031258594_email_temp_blue-min.png",
  },
  {
    value: "dark",
    label: "Dark",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/25020312585854_email_temp_dark-min.png",
  },
  {
    value: "winter",
    label: "Winter",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/25020315545118_email_temp_winter-min.png",
  },
];

const Dropdown = ({
  name,
  onChange,
  value,
}: ControllerRenderProps<sendMsgFormType, "template">) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleOptionClick = (option: any) => {
    onChange(option);
  };

  return (
    <div className="relative">
      <div
        className="flex items-center justify-between px-4 py-2 border border-gray-300 rounded-md cursor-pointer"
        onClick={() => setShowOptions(!showOptions)}
      >
        {options.find((opt) => opt.value === value)?.label || "Default"}
        {showOptions ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
      </div>
      <div
        className={`absolute top-full left-0 mt-2 w-full rounded-md shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.06)] shadow-md border border-gray-300 bg-white z-10 ${
          showOptions ? "block" : "hidden"
        }`}
      >
        <ul className="p-2">
          {options.map((option) => (
            <li
              key={option.value}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
              onClick={() => {
                handleOptionClick(option.value);
                setShowOptions(false);
              }}
            >
              <span>{option.label}</span>
              {option.icon && (
                <img
                  src={option.icon}
                  alt={option.label}
                  className="w-6 h-6 mr-2"
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SendPersonalMessage = ({
  show,
  ctrFn,
  entity_user_identification,
  toSendMsgUserId,
  entity_identification,
  userName,
}: propTypes) => {
  const { t } = useTranslation();

  const attachmentInputRef = useRef<HTMLInputElement | null>(null);

  const [htmlContent, setHtmlContent] = useState("");
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]); // State to store uploaded files

  const schema = Joi.object()
    .keys({
      template: Joi.string().default("old").optional(),
      subject: Joi.string()
        .max(100)
        .optional()
        .allow(null, "")
        .label(t("dashboard.entities.viewEntityUser.sendMsg.inp1Label")),
      content: Joi.string()
        .min(5)
        .max(3000)
        .required()
        .label(t("dashboard.entities.viewEntityUser.sendMsg.inp2Label")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    control,
  } = useForm<sendMsgFormType>({
    resolver: joiResolver(schema),
    defaultValues: {
      template: "old",
    },
  });

  const getTemplate = () => {
    let template = "sum_user_message";

    switch (watch().template) {
      case "old":
        template = "sum_user_message";
        break;
      case "white":
        template = "sum_user_message_white";
        break;
      case "elegant":
        template = "sum_user_message_elegant";
        break;
      case "default":
        template = "sum_user_message_default";
        break;
      case "dark":
        template = "sum_user_message_dark";
        break;
      case "winter":
        template = "sum_user_message_winter";
        break;
      default:
        template = "sum_user_message";
    }

    return template;
  };

  const sendMsgToUserMutation = useMutation<any, AxiosError<any, any>, any>(
    (data: any) =>
      HttpService.post(
        `/${entity_user_identification}/${entity_identification}/user/${toSendMsgUserId}/message`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Message is sent.");
        reset();
        ctrFn(false);
        setAttachments([]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const requestEmailPreview = useMutation<any, AxiosError<any, any>, any>(
    (data: any) =>
      HttpService.post(
        `/${entity_user_identification}/${entity_identification}/entity/template/${getTemplate()}/preview`,
        {
          projectName: data.projectName,
          title: data.subject,
          userName: data.userName,
          messageText: data.content,
        },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (response) => {
        setHtmlContent(response.data);
        setShowEmailPreview(true);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleRequestEmailView = () => {
    if (isValid) {
      const { content, subject } = watch();
      requestEmailPreview.mutate({
        projectName: "",
        subject,
        userName,
        content,
      });
    }
  };

  const onSubmit = async (data: sendMsgFormType) => {
    const formData = new FormData();

    formData.set("subject", data.subject);
    formData.set("content", data.content);
    formData.set("template", getTemplate());

    attachments.forEach((file) => {
      formData.append("files", file);
    });

    sendMsgToUserMutation.mutate(formData);
  };

  const handleTrigerFileSelection = () => {
    if (attachmentInputRef.current) {
      attachmentInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      if (selectedFiles.length + attachments.length <= 5) {
        setAttachments((prevFiles) => [...prevFiles, ...selectedFiles]);
      } else {
        toast.error(
          t("dashboard.entities.viewEntityUser.sendMsg.maxUploadFiles")
        );
      }
    }
  };

  const removeFile = (index: number) => {
    setAttachments((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onClose={() => {
          reset();
          ctrFn(false);
        }}
        className="myModal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className="border-0 p-4">
            {t("dashboard.entities.viewEntityUser.sendMsg.modal-title")}
          </Modal.Header>
          <Modal.Body className="p-4">
            {/* Email template */}
            <div className="mb-3">
              <Label
                value={t(
                  "dashboard.entities.viewEntityUser.sendMsg.templateLabel"
                ).toString()}
                className="block mb-3"
              />
              <Controller
                control={control}
                name="template"
                render={({ field }) => <Dropdown {...field} />}
              />
            </div>

            {/* Subject */}
            <div className="mb-3 relative">
              <Label
                value={t(
                  "dashboard.entities.viewEntityUser.sendMsg.inp1Label"
                ).toString()}
                className="block mb-3"
              />

              <TextInput
                color={!!errors.subject ? "failure" : ""}
                type="text"
                placeholder={
                  t(
                    "dashboard.entities.viewEntityUser.sendMsg.inp1Placeholder"
                  ) as string
                }
                helperText={<>{errors?.subject?.message}</>}
                {...register("subject")}
              />
            </div>

            {/* Description */}
            <div className="mb-3 relative">
              <Label
                value={t(
                  "dashboard.entities.viewEntityUser.sendMsg.inp2Label"
                ).toString()}
                className="block mb-3"
              />

              <Textarea
                color={!!errors.content ? "failure" : ""}
                rows={7}
                className="pb-10"
                placeholder={
                  t(
                    "dashboard.entities.viewEntityUser.sendMsg.inp2Placeholder"
                  ) as string
                }
                helperText={<>{errors?.content?.message}</>}
                {...register("content")}
              />

              <div className="text-end text-[#687076] text-xs font-normal font-['Figtree'] leading-none">
                {t("dashboard.entities.viewEntityUser.sendMsg.inp2text")}
              </div>

              <div
                className={`flex items-center justify-between absolute ${
                  !!errors.content ? "bottom-14" : "bottom-10"
                } left-0 right-0 w-full px-4`}
              >
                <button
                  onClick={handleTrigerFileSelection}
                  className="h-8 p-1 bg-white rounded-md shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06)] border border-gray-300 justify-start items-center gap-2.5 inline-flex"
                  type="button"
                >
                  <BiPaperclip size={20} />
                </button>

                <button
                  type="button"
                  onClick={handleRequestEmailView}
                  disabled={!isValid || requestEmailPreview.isLoading}
                  className="h-8 pl-2 pr-3 py-1 bg-white disabled:bg-gray-200 rounded-md shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06)] border border-gray-300 justify-start items-center gap-1 inline-flex"
                >
                  <BiShow size={20} />
                  <span className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">
                    {t("dashboard.entities.viewEntityUser.sendMsg.key1")}
                  </span>
                </button>
              </div>
            </div>

            {/* Attachments */}
            <div className="mb-3">
              <input
                ref={attachmentInputRef}
                type="file"
                multiple
                accept={ACCEPTED_FILE_TYPES}
                onChange={handleFileChange}
                hidden
              />
              {attachments.length > 0 && (
                <ul className="flex items-center flex-wrap gap-4">
                  {attachments.map((file, index) => (
                    <li
                      key={index}
                      className="flex w-auto w-full md:w-auto items-center border border-dashed border-gray-200 p-1 pr-4 text-sm"
                    >
                      <Button
                        size={"sm"}
                        color={""}
                        type="button"
                        onClick={() => removeFile(index)}
                        className="p-0"
                      >
                        <BiTrash color="red" />
                      </Button>
                      <span className="truncate">{file.name} </span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-end bg-gray-50">
            <Button
              type="button"
              onClick={() => {
                reset();
                ctrFn(false);
                setAttachments([]);
              }}
              color={"light"}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              className="bg-sky-500"
              isProcessing={sendMsgToUserMutation.isLoading}
              disabled={sendMsgToUserMutation.isLoading}
            >
              {t("dashboard.entities.viewEntityUser.sendMsg.submitBtn")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Modal preview email */}
      <Modal
        dismissible
        show={showEmailPreview}
        onClose={() => setShowEmailPreview(false)}
        size={"5xl"}
      >
        <Modal.Header>
          {t("dashboard.entities.viewEntityUser.sendMsg.key2")}
        </Modal.Header>
        <Modal.Body>
          <div
            className="h-full w-full"
            dangerouslySetInnerHTML={{
              __html: htmlContent,
            }}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SendPersonalMessage;
