import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import {
  INormalizedLmcDataType,
  lmcOptionComponentProps,
  pointCardFormProps,
} from "./types";
import LmcUtil from "./util";
import { Button, Label, Textarea, TextInput } from "flowbite-react";
import { HiTrash } from "react-icons/hi";
import LMCButtons from "../../../public/LMCButtons";

const schema = Joi.object()
  .keys({
    values: Joi.array()
      .items(
        Joi.object({
          value: Joi.number()
            .custom((value, helper) => {
              if (!LmcUtil.validatePrecision(value, 2)) {
                // Define the error message using helper.message()
                return helper.message(
                  "Number must have at most 2 decimal places" as any
                );
              }
              return value;
            })
            .positive()
            .min(1)
            .max(100)
            // .required()
            .allow(null)
            .allow("")
            .optional()
            .label("Default amount"),
        })
      )
      .unique()
      .min(1)
      .max(3)
      .custom((value, helper) => {
        if (!LmcUtil.uniqueValues(value, "value")) {
          return helper.message(
            "Default amounts must contain unique values for default amount" as any
          );
        }
        return value;
      })
      .custom((value, helper) => {
        if (!LmcUtil.atLeastOneItemIsFilled(value)) {
          return helper.message(
            "Default amounts must contain at least one value" as any
          );
        }
        return value;
      })
      .required()
      .label("Default amounts")
      .messages({
        "array.includesRequiredKnowns":
          "Add at least one value to the quick access buttons",
      }),
    defaultValue: Joi.string().optional().label("Default value"),
    customValue: Joi.boolean().required().label("Custom value"),
    comment: Joi.string().max(100).optional().allow("", null).label("Comment"),
  })
  .required();

const PercentageCard = ({
  label,
  optionData,
  onSubmitCardData,
  playgroundDataHandler,
  handleCancelEdit,
}: lmcOptionComponentProps) => {
  const [t] = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    trigger,
  } = useForm<pointCardFormProps>({
    resolver: joiResolver(schema),
    mode: "onSubmit",
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "values", // unique name for your Field Array
  });

  useEffect(() => {
    if (optionData) {
      let denormalizedData = LmcUtil.unwrapper(
        "PERCENTAGE_CARD",
        optionData
      ) as pointCardFormProps;

      reset({ ...denormalizedData });
    }
  }, [optionData, reset]);

  const onSubmit = (data: pointCardFormProps) => {
    const newData = {
      ...data,
      values: data.values
        .filter((item: any) => item.value !== null)
        .filter((item: any) => item.value !== "")
        .map((v: { value: any }) => v.value),
    };

    const normalizedData = LmcUtil.wrapper(
      "PERCENTAGE_CARD",
      newData
    ) as INormalizedLmcDataType;

    onSubmitCardData("PERCENTAGE_CARD", normalizedData).then(() => {
      reset();
    });
  };

  useEffect(() => {
    watch((value, { name, type }) => {
      const newData = {
        ...value,
        values: value?.values
          ?.filter((item: any) => item.value !== null)
          ?.filter((item: any) => item.value !== "")
          ?.map((v) => v?.value),
      };

      playgroundDataHandler({
        type: "PERCENTAGE_CARD",
        data: LmcUtil.wrapper(
          "PERCENTAGE_CARD",
          newData
        ) as INormalizedLmcDataType,
      });
    });
  }, [playgroundDataHandler, watch]);

  return (
    <div className="lmc-card point-card w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, index) => (
          <div className="mb-3" key={item.id}>
            <Label className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
              {t("dashboard.features.lmc.pointCard.label1")}
            </Label>
            <div className="grid grid-cols-7">
              <div
                className={`${fields.length > 1 ? "col-span-6" : "col-span-7"}`}
              >
                <TextInput
                  helperText={<>{errors.values?.[index]?.value?.message}</>}
                  color={
                    !!errors.values?.[index]?.value ||
                    (watch("values")[index]?.value as number) > 100
                      ? "failure"
                      : ""
                  }
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  min={1}
                  max={100}
                  step=".01"
                  placeholder={
                    t("dashboard.features.lmc.pointCard.placeholder1") as string
                  }
                  {...register(`values.${index}.value`, {
                    onChange(event) {
                      trigger();
                    },
                  })}
                />
              </div>
              {fields.length > 1 && (
                <div className="col-span-1">
                  <Button
                    color={""}
                    className="text-gray-300"
                    onClick={() => remove(index)}
                  >
                    <HiTrash size={22} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}

        {watch("values")?.length < 3 && (
          <Button
            type="button"
            color="light"
            onClick={() => append({ value: null })}
            className="mb-3 w-full"
          >
            {t("dashboard.features.lmc.pointCard.key1")}
          </Button>
        )}

        {!!errors.values && (
          <p className="text-red-600 text-sm mb-3">
            {errors.values?.message as any}
          </p>
        )}

        <div className="flex items-center gap-2 mb-3">
          <label className="switch shrink-0">
            <input type="checkbox" {...register("customValue")} />
            <div className="slider"></div>
          </label>
          <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
            {t("dashboard.features.lmc.percentageCard.label4")}
          </span>
        </div>

        <div className="w-full h-px bg-gray-300 mb-3" />

        <div className="mb-3">
          <Label
            htmlFor="comment"
            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
          >
            {t("dashboard.features.lmc.percentageCard.label3")}
          </Label>
          <Textarea
            color={!!errors.comment ? "failure" : ""}
            rows={3}
            placeholder={
              t("dashboard.features.lmc.percentageCard.placeholder3") as string
            }
            helperText={<>{errors.comment?.message}</>}
            {...register("comment")}
          />
        </div>

        <LMCButtons handleCancelEdit={handleCancelEdit} />
      </form>
    </div>
  );
};

export const PercentageCardUi = ({
  data,
}: {
  data: INormalizedLmcDataType | null;
}) => {
  const { t } = useTranslation();

  return (
    <div className="self-stretch  flex-col justify-start items-center gap-6 flex">
      <h4 className="text-2xl font-bold text-gray-800">
        {t("dashboard.features.loginTemplate.tempPart.btn-01")}
      </h4>

      <div className="self-stretch  bg-white rounded-2xl shadow flex-col justify-start items-center gap-6 flex">
        {/* <div className="self-stretch px-4 pt-4 flex-col justify-start items-center gap-2.5 flex">
          <div className="self-stretch p-4 rounded-lg border border-dashed border-gray-300 flex-col justify-center items-center gap-3 flex">
            <div className="flex-col justify-center items-center gap-1 flex">
              <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
                {t("dashboard.features.lmc.percentageCard.key2")}
              </div>
              <div className="text-center text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
                25%
              </div>
            </div>
            <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
              <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                <div className="text-center text-red-600 text-base font-semibold font-['Figtree'] leading-normal">
                  {t("dashboard.features.lmc.percentageCard.key3")}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="self-stretch px-4 pb-5 flex-col justify-center items-center gap-3 flex pt-4">
          <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
            {t("dashboard.features.lmc.percentageCard.key4")}
          </div>
          {data?.options?.custom_value && (
            <div className="self-stretch h-11 flex-col justify-center items-center gap-4 flex">
              <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
                <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                  <div className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                    {t("dashboard.features.lmc.percentageCard.key5")}
                  </div>
                </div>
              </div>
            </div>
          )}
          {data?.pre_defined_value?.values?.map((v, index) => {
            if (v > 0 && v <= 100 && LmcUtil.validatePrecision(+v, 2))
              return (
                <div
                  key={index}
                  className="self-stretch h-11 flex-col justify-center items-center gap-4 flex"
                >
                  <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
                    <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                      <div className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                        {Math.abs(+v)}%
                      </div>
                    </div>
                  </div>
                </div>
              );
            else return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default PercentageCard;
