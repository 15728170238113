import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import HttpService from "../../../../services/http";
import useAuth from "../../../../hooks/useAuth";
import moment from "moment";
import { Button, Checkbox, Label, Modal } from "flowbite-react";
import { FaChevronDown } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { HiDocumentSearch } from "react-icons/hi";

const schema = Joi.object({
  events: Joi.array().min(1).required(),
}).required();

const UserTracker = ({ isRoute = false }: { isRoute?: boolean }) => {
  const { t } = useTranslation();
  const { customer_identification, userEndedSubscription, user } = useAuth();
  const { user_identification } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
  } = useForm<{ events: string[] }>({
    resolver: joiResolver(schema),
  });

  const [show, setShow] = useState(false);
  const [trackerResult, setTrackerResult] = useState<string>("");
  const [trackerObjResult, setTrackerObjResult] = useState<any[]>([]);
  const [eventDetail, setEventDetail] = useState<Record<string, any>>();
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);

  const { data, isSuccess, isError, isLoading, refetch } = useQuery(
    "tracker-options",
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/events`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleCloseOptionsModal = () => {
    setShow(false);
  };

  const trackerMutation = useMutation<any, AxiosError<any, any>, string[]>(
    (events: string[]) =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/${user_identification}/events?events=${events}&limit=100`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (data: any) => {
        setTrackerResult(JSON.stringify(data?.data?.element, null, "\t"));
        const sortedItems = data?.data?.element?.sort((a: any, b: any) => {
          const dateA = new Date(a.date_time).getTime();
          const dateB = new Date(b.date_time).getTime();

          return dateB - dateA;
        });
        setTrackerObjResult([...sortedItems]);

        handleCloseOptionsModal();
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleDownloadTrackerResult = (result: string) => {
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(result);

    let linkRef: HTMLAnchorElement = document.createElement("a");
    linkRef.href = dataStr;
    linkRef.download = "trackerResult.json";

    linkRef.click();
  };

  const onSubmit = (values: { events: string[] }) => {
    trackerMutation.mutate(values.events);
  };

  const handleShowEventDetails = (event: Record<string, any>) => {
    setEventDetail(event);
    setShowEventDetailsModal(true);
  };

  let optionsContent;

  if (isLoading) optionsContent = <p>Loading...</p>;

  if (isError)
    optionsContent = (
      <div>
        <h4>Failed to fetch options.</h4>
        <Button color="light" onClick={() => refetch()}>
          Retry
        </Button>
      </div>
    );

  if (isSuccess)
    optionsContent = (
      <div className="max-h-[320px] overflow-y-auto mb-4">
        {!!errors.events && (
          <small className="block mb-3 text-red-500">
            {errors.events.message}
          </small>
        )}
        {data.data.element
          .slice(0)
          .reverse()
          .map((item: string) => (
            <div className="flex items-center gap-2 mb-4" key={item}>
              <Checkbox
                id={`track-option-${item}`}
                value={item}
                className="bg-white focus:ring-0"
                {...register("events", {
                  onChange(event) {
                    if (event.target.value === "ALL") {
                      reset({ events: ["ALL"] });
                    } else {
                      reset({
                        events: getValues("events").filter(
                          (item) => item !== "ALL"
                        ),
                      });
                    }
                  },
                })}
              />
              <Label
                htmlFor={`track-option-${item}`}
                className="flex text-gray-900 text-xs font-normal font-['Figtree'] leading-none"
              >
                {item.replaceAll("_", " ")}
              </Label>
            </div>
          ))}
      </div>
    );

  useEffect(() => {
    if (isSuccess) {
      reset({ events: ["ALL"] });

      trackerMutation.mutate(["ALL"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <div className={`p-6`}>
      {isRoute && (
        <Link
          to={"/control-panel/users"}
          className="gap-2 inline-flex items-center mb-4"
        >
          <div className="w-5 h-5 relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.70711 16.7071C9.31659 17.0976 8.68342 17.0976 8.2929 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.2929 3.29289C8.68342 2.90237 9.31659 2.90237 9.70712 3.29289C10.0976 3.68342 10.0976 4.31658 9.70712 4.70711L5.41422 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41422 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                fill="#9CA3AF"
              />
            </svg>
          </div>
          <div className="grow shrink basis-0 text-gray-500 text-sm font-semibold font-['Figtree'] leading-tight">
            {t("dashboard.entities.viewEntityUser.key1")}
          </div>
        </Link>
      )}

      <h2 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-none mb-2">
        {t("tracker.title")}
      </h2>
      <p className="text-gray-900 text-base font-normal font-['Figtree'] leading-normal">
        {t("tracker.description")}
      </p>

      <button
        className={`${
          !isRoute ? "w-full" : "w-[300px]"
        } px-[13px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-between items-center inline-flex my-6`}
        onClick={() => setShow(true)}
        disabled={userEndedSubscription}
      >
        {getValues("events")?.indexOf("ALL") <= -1
          ? t("tracker.btn-1")
          : t("tracker.btn-2")}
        <FaChevronDown size={12} />
      </button>

      {trackerMutation.isSuccess && (
        <div className="tracker-results my-3">
          <div
            className="result-bx bg-light rounded-2"
            style={{ maxHeight: 300, overflowY: "auto" }}
          >
            {trackerObjResult.length <= 0 ? (
              <div className="result-row">
                <h4>{t("tracker.text-1")}</h4>
              </div>
            ) : (
              <ol className="list-none">
                {trackerObjResult.map((item: any, index: number) => (
                  <li
                    key={index}
                    className="mb-4 flex flex-row justify-between"
                  >
                    <div className="content">
                      <h3 className="text-gray-900 text-base font-medium font-['Figtree'] leading-normal">
                        {item?.value?.message}
                      </h3>
                      <p className="text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                        {moment(item?.date_time).format("MMMM DD | HH:mm:ss")}
                      </p>
                    </div>

                    {item?.value?.value && (
                      <button
                        className="border-none ring-0 outline-none"
                        onClick={() => handleShowEventDetails(item)}
                      >
                        <HiDocumentSearch color="gray" size={22} />
                      </button>
                    )}
                  </li>
                ))}
              </ol>
            )}
          </div>

          <Button
            onClick={() => handleDownloadTrackerResult(trackerResult)}
            disabled={
              !trackerMutation.data.data.element ||
              trackerMutation.data.data.element.length <= 0
            }
            className="bg-sky-500 w-full mt-6"
          >
            <i className="bi bi-download"></i>
            <span className="mx-2">{t("tracker.btn-download")}</span>
          </Button>
        </div>
      )}

      <Modal show={show} onClose={handleCloseOptionsModal} size={"md"}>
        <Modal.Header>
          {t("tracker.title")}
          <p className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight mt-2">
            {t("tracker.description-modal")}
          </p>
        </Modal.Header>
        <Modal.Body>
          <form className="options" onSubmit={handleSubmit(onSubmit)}>
            {optionsContent}

            <Button
              type="submit"
              className="bg-sky-500 w-full"
              disabled={!isValid || trackerMutation.isLoading}
              isProcessing={trackerMutation.isLoading}
            >
              {t("tracker.btn-submit-modal")}
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      {/* Event detail modal */}
      <Modal
        show={showEventDetailsModal}
        onClose={() => {
          setEventDetail(undefined);
          setShowEventDetailsModal(false);
        }}
        dismissible
      >
        <Modal.Header className="border-0">
          <h3 className="text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
            {eventDetail?.value?.message}
          </h3>
          <p className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
            {moment(eventDetail?.date_time).format("MMMM DD | HH:mm:ss")}
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="border border-gray-300 rounded-md p-4 text-gray-600 text-sm font-normal font-['Figtree'] leading-tight break-words">
            {JSON.stringify(eventDetail?.value?.value)}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserTracker;
